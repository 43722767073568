import { useSistema } from '../hooks/useSistema';
import { lazy, Suspense } from 'react';
import '../componentes/layout/util/Custom.css'
import { LoaderSuspense } from '../componentes/layout/loader/LoaderSuspense';

const FormLogin = lazy(() => import('../modulos/login/FormLogin').then(module => {
    return { default: module.FormLogin }
}))

const App = lazy(() => import('./App').then(module => {
    return { default: module.App }
}))

export function LoginOrApp(){
    const { usuario, validToken } = useSistema()

    return (
        <>
            <Suspense fallback={<LoaderSuspense />}>
                {((usuario.id && validToken) ? <App /> : <FormLogin />)}
            </Suspense>
        </>
    )
}