import axios from 'axios'
import { toast } from 'react-toastify'

const hostname = window.location.hostname

const servidorOficial = !((hostname === 'localhost') || (hostname === '127.0.0.1') || (hostname.startsWith('teste')))

const urlServer = (servidorOficial ? 'https://server.sistemaespiga.com.br/' : 'https://teste.server.sistemaespiga.com.br/')


export const api = axios.create({
    baseURL: urlServer,
})

export function getImagem(imagem){
    try {
        return `${urlServer}?servico=getImagem&imagem=${imagem}` 
    } catch (error) {
        return ""
    }
}

export async function getDadosAPI(rota = '', filtro = {}, lista = true){
    try {
        const res = await api.post('?servico=' + rota, filtro)
        if(res.data.situacao === "ok"){
            return (lista ? res.data.arrayObj : res.data.obj)
        } else {
            res.data.erro.forEach(e => {
                toast.error(e.msg)
            })
        }
        return (lista ? [] : {})
    } catch (error) {
        toast.error(error.message)

        return (lista ? [] : {})
    }
}

export async function setDadosAPI(rota = '', dados){
    try {
        const res = await api.post('?servico=' + rota, dados)
        if(res.data.situacao === "ok"){
            return true
        } else {
            res.data.erro.forEach(e => {
                toast.error(e.msg)
            })
        }
        return false
    } catch (error) {
        toast.error(error.message)

        return false
    }
}

export async function requisicaoXHR(url, body, method = 'POST'){
    return new Promise((resolve, reject) => {
        try {
            var xhr = new XMLHttpRequest()
            xhr.open(method, `${urlServer}?servico=${url}`, true)
            xhr.onload = function () {
                if(this.status >= 200 && this.status < 300){
                    const res = JSON.parse(xhr.response)
                    if(res.situacao === "ok"){
                        resolve(res)
                    } else {
                        res.erro.forEach(e => {
                            toast.error(e.msg)
                        })
                        resolve(false)
                    }
                } else {
                    toast.error(xhr.statusText)
                    resolve(false)
                }
            }
            xhr.onerror = function () {
                toast.error('Falha ao realizar requisição')
                resolve(false)
            }

            xhr.send(body);
        } catch (error) {
            toast.error('Falha ao realizar requisição')
            resolve(false)
        }
    })
}