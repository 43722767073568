import './globals'
import './dependencias'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { LoginOrApp } from './main/LoginOrApp';
import { SistemaProvider } from './hooks/useSistema';
import { LoaderProvider } from './hooks/useLoader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toastifyConfig } from './consts';


ReactDOM.render(
  // <React.StrictMode>
      <LoaderProvider>
        <ToastContainer {...toastifyConfig} />
        <SistemaProvider>
          <LoginOrApp />
        </SistemaProvider>
      </LoaderProvider>,
  // </React.StrictMode>,
  document.getElementById('root')
)