import LoaderSpinner from "react-loader-spinner";
import { useLoader } from '../../../hooks/useLoader';

export function Loader(){
    const { loader } = useLoader()

    return (
        <>
            {((loader !== "") ?
                <div className={`overlay${(loader !== "loaderLogin" ? ' overlay-custom' : '')}`}>
                    <LoaderSpinner
                        type="Puff"
                        color="#1C4F25"
                        // color="#3c8dbc"
                        height={100}
                        width={100}
                        visible={true} 
                    />
                </div>
            : null)}
        </>
    )
}